/**
  Note on theming used in this file:

  This theming system is in the progress of being made REDUNDANT and should not be used where possible.
  Either use Material UI's theme overrides for reusable components, or use inline colours from the MUI Palette Ref.

*/

@font-face {
  font-family: "Inter";
  src: url("./fonts/inter/Inter.ttc") format("ttc");
  font-weight: 100 900;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF; /* Latin glyphs
}

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
}
@supports (font-variation-settings: normal) {
  :root { font-family: InterVariable, sans-serif; }
}


:root {
  --body-font: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --heading-font: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  /**
    COLORS
   */

  /* Common */
  --white: #FFFFFF;
  --grey-97: hsla(0, 0%, 97%, 1);
  --grey-95: hsla(0, 0%, 95%, 1);
  --grey-90: hsla(0, 0%, 90%, 1);
  --grey-80: hsla(0, 0%, 80%, 1);
  --grey-60: hsla(0, 0%, 60%, 1);
  --grey-50: hsla(0, 0%, 50%, 1);
  --grey-40: hsla(0, 0%, 40%, 1);
  --grey-20: hsla(0, 0%, 20%, 1);
  --grey-10: hsla(0, 0%, 10%, 1);

  --transucent-ui-color: hsla(0, 0%, 60%, 0.85);

  --body-color: var(--grey-10);


  /* UX */
  --ux-error: #FF0000;
  --ux-success: #FF0000;
  --ux-warning: #FF0000;
  --ux-notice: #FF0000;

  --action-primary-hsl: 190, 61%, 53%;
  --action-primary: hsla(var(--action-primary-hsl), 1);
  --action-primary-hover: hsla(190, 61%, 45%, 1);
  
  /* Brand - Primary (Light blue) */
  --brand-primary-hsl: 190, 61%, 53%;
  --brand-primary: hsla(var(--brand-primary-hsl), 1);
  
  /* Brand - Secondary (Pink) */
  --brand-secondary-hsl: 300, 100%, 50%;
  --brand-secondary: hsla(var(--brand-secondary-hsl), 1);
  
  /* Brand - Tertiary (Purple) */
  --brand-tertiary-hsl: 250, 100%, 10%;
  --brand-tertiary: hsla(var(--brand-tertiary-hsl), 1);

}

body {
  margin: 0;
  font-family: var(--body-font);
  color: var(--body-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p a {
  color: var(--action-primary);
  text-decoration-color: hsla(var(--action-primary-hsl), 0.5);
  transition: 
    text-decoration-color 0.2s ease-in-out, 
    color 0.2s ease-in-out;
  text-decoration-thickness: 2px;
}