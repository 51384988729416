.appLayout {
  background: var(--grey-97);
  display: grid;
  grid-template-areas: 'sidebar main';
  min-height: 100vh;
}

// Assigned in the component with useMediaQuery hook
.appLayout_isMobile {
  --sidebar-padding: 1rem;
  grid-template-columns: 0 1fr;
}
.appLayout_isDesktop {
  --sidebar-padding: 2rem;
  // grid-template-columns: minmax(180px, 260px) 1fr;
}

.appLayout__sidebar {
  background: #090038;
  color: #00BBFF;
  grid-area: sidebar;
}

.appLayout__sidebarLogo {
  display: flex;
  align-items: center;
  padding: var(--sidebar-padding);
  padding-left: 1rem;
  padding-bottom: 1rem;
  img {
    width: 140px;
  }

  .appLayout__env {
    margin-left: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 15px
  }
}

.appLayout__main {
  grid-area: main;
  background: #F8F7FD;
}


